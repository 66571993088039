<template>
  <div
    class="blog_management"
    style="overflow: hidden; padding: 5px 10px 10px 10px"
  >
    <AddBlog :getBlog="getBlog" />
    <div v-if="!isLoading" class="Container Flipped">
      <table class="Content">
        <tr>
          <th>{{ this.$t("admin.active") }}</th>
          <th>{{ this.$t("admin.date") }}</th>
          <th>{{ this.$t("admin.title") }}</th>
          <th>{{ this.$t("add_blog.subtitle") }}</th>
          <th>{{ this.$t("add_blog.author") }}</th>
          <th>{{ this.$t("admin.description") }}</th>
          <th>{{ this.$t("admin_sidebar.category") }}</th>
          <th>{{ this.$t("admin.image") }}</th>
          <th>{{ this.$t("admin_sidebar.video") }}</th>
          <th>{{ this.$t("admin.keywords") }}</th>
          <th>{{ this.$t("admin.edit") }}</th>
          <th>{{ this.$t("admin.delete") }}</th>
        </tr>
        <tr v-for="(blog, index) in allBlog" :key="index">
          <td class="show_on_front">
            <i v-if="blog.show == true" class="fal fa-check-circle"></i>
            <i v-if="blog.show == false" class="fal fa-ban"></i>
          </td>
          <td>
            <div v-if="lang == 'ka'">{{ blog.dateKA }}</div>
            <div v-else>{{ blog.dateEN }}</div>
          </td>
          <td>
            <div
              v-if="lang == 'ka'"
              class="short_text"
              v-html="blog.titleKA"
            ></div>
            <div v-else class="short_text" v-html="blog.titleEN"></div>
          </td>
          <td>
            <div
              v-if="lang == 'ka'"
              class="short_text"
              v-html="blog.subTitleKA"
            ></div>
            <div v-else class="short_text" v-html="blog.subTitleEN"></div>
          </td>
          <td>
            <div
              v-if="lang == 'ka'"
              class="short_text"
              v-html="blog.authorKA"
            ></div>
            <div v-else class="short_text" v-html="blog.authorEN"></div>
          </td>
          <td>
            <div
              v-if="lang == 'ka'"
              :ref="'descriptionKA' + index"
              class="short_text"
              style="padding-left: 20px"
            ></div>
            <div
              v-else
              :ref="'descriptionEN' + index"
              class="short_text"
              style="padding-left: 20px"
            ></div>
          </td>
          <td>
            <div v-if="blog.category !== null">
              <div
                v-if="lang == 'ka'"
                class="short_text"
                v-html="blog.category.titleKA"
              ></div>
              <div
                v-else
                class="short_text"
                v-html="blog.category.titleEN"
              ></div>
            </div>
          </td>
          <td>
            <img
              v-if="blog.image != 'null'"
              style="height: 100px; width: 150px"
              :src="`${server_url}/${blog.image}`"
              :alt="blog.titleKA"
            />
          </td>
          <td>
            <iframe
              v-if="blog.video !== null"
              width="150"
              height="100"
              :src="blog.video"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </td>
          <td>
            <div v-for="item in blog.keywords" :key="item.id">
              {{ item }}
            </div>
          </td>
          <td>
            <b-button
              @click="
                (modalShow = !modalShow),
                  editBlog(
                    blog._id,
                    blog.show,
                    blog.titleEN,
                    blog.titleKA,
                    blog.subTitleKA,
                    blog.subTitleEN,
                    blog.authorEN,
                    blog.authorKA,
                    blog.descriptionKA,
                    blog.descriptionEN,
                    blog.category,
                    blog.image,
                    blog.video,
                    blog.keywords
                  )
              "
            >
              <i class="fal fa-pen"></i>
            </b-button>
          </td>
          <td>
            <ConfirmDelete @clicktodelete="deleteBlog(blog._id)" />
          </td>
        </tr>
      </table>
    </div>
    <div v-else>
      <b-skeleton-table
        :rows="5"
        :columns="8"
        :table-props="{ bordered: true, striped: true }"
      ></b-skeleton-table>
    </div>
    <b-modal id="modal-xl-2" v-model="modalShow" size="xl" hide-footer>
      <div class="text_editor">
        <label for="">{{ $t("admin.title") }} (KA)</label>
        <b-form-input v-model="update.titleKA"></b-form-input>
        <label for="">{{ $t("admin.title") }} (EN)</label>
        <b-form-input v-model="update.titleEN"></b-form-input>
      </div>
      <div class="text_editor">
        <label for="">{{ $t("add_blog.subtitle") }} (KA)</label>
        <vue-editor v-model="update.subTitleKA"></vue-editor>
        <label for="">{{ $t("add_blog.subtitle") }} (EN)</label>
        <vue-editor v-model="update.subTitleEN"></vue-editor>
      </div>
      <div class="text_editor">
        <label for="">{{ $t("add_blog.author") }} (KA)</label>
        <vue-editor v-model="update.authorKA"></vue-editor>
        <label for="">{{ $t("add_blog.author") }} (EN)</label>
        <vue-editor v-model="update.authorEN"></vue-editor>
      </div>
      <div class="text_editor">
        <label for="">{{ $t("admin.description") }} (KA)</label>
        <vue-editor v-model="update.descriptionKA"></vue-editor>
        <label for="">{{ $t("admin.description") }} (EN)</label>
        <vue-editor v-model="update.descriptionEN"></vue-editor>
      </div>
      <div class="text_editor">
        <label for="">{{ $t("admin_sidebar.video") }}</label>
        <input class="input" v-model="update.video" />
      </div>
      <div class="text_editor" v-if="update.category !== null">
        <label for="">{{ $t("add_category.selected_category") }}</label>
        <input class="input" v-model="update.category.titleKA" readonly />
      </div>
      <div>
        <label for="">{{ $t("admin_sidebar.category") }}</label>
        <select name="" id="" v-model="update.category">
          <option value="" selected disabled>
            {{ $t("add_category.choose_category") }}
          </option>
          <option
            v-for="category in allCategory"
            :key="category.id"
            :value="category"
          >
            <span v-if="lang == 'ka'">{{ category.nameKA }}</span>
            <span v-els>{{ category.nameEN }}</span>
          </option>
        </select>
      </div>
      <div class="text_editor" v-if="update.keywords.length !== 0">
        <label for="">{{ $t("admin.choosed_keywords") }}</label>
        <div
          v-for="(item, index) in update.keywords"
          :key="index"
          class="d-flex"
        >
          <div class="choosed_keywords">
            {{ item }}
          </div>
          <i
            class="fal fa-times-square"
            style="cursor: pointer; margin-left: 20px; font-size: 20px"
            @click="delete_keywords(index)"
          ></i>
        </div>
      </div>
      <div class="text_editor add_comp">
        <label for="">{{ $t("admin.keywords") }}</label>
        <i class="fal fa-plus-square" @click="add_component()"></i>
        <div v-for="(comp, i) in components" :key="comp.id" class="d-flex">
          <input
            class="input add_component"
            type="text"
            v-model="comp[i]"
            @change="get_value($event)"
          />
          <i class="fal fa-times-square delete_comp" @click="delete_comp"></i>
        </div>
      </div>
      <div>
        <label for="">{{ $t("admin.activate_on_front") }}</label>
      </div>
      <input class="checkbox" type="checkbox" v-model="update.show" />
      <div>
        <img
          style="width: 150px; height: 100px"
          :src="`${server_url}/${update.image}`"
          alt="await for new image to upload"
          class="image"
        />
      </div>
      <input
        type="file"
        style="margin: 10px 0"
        accept="image/jpg, image/png, image/jpeg"
        class="input"
        @change="previewFiles($event)"
      />
      <button
        type="submit"
        class="submit btn btn-primary"
        @click="UpdateBlog()"
      >
        <span v-show="!isLoading">{{ $t("admin.edit") }}</span>
        <span v-show="isLoading">
          {{ $t("login.loading") }}
          <spinner v-show="isLoading" text="loading" />
        </span>
      </button>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import env from "../../../env.json";
import { VueEditor } from "vue2-editor";
import AddBlog from "../AddComponents/AddBlogs.vue";
import spinner from "../../spinner/Spinner.vue";
import Swal from "sweetalert2";
import ConfirmDelete from "../../../components/Confirm/DeleteConfirm.vue";

export default {
  name: "getBlog",
  components: {
    AddBlog,
    VueEditor,
    spinner,
    ConfirmDelete,
  },
  data() {
    return {
      table_header: [
        this.$t("admin.active"),
        this.$t("admin.title"),
        this.$t("add_blog.subtitle"),
        this.$t("add_blog.author"),
        this.$t("admin.description"),
        this.$t("admin_sidebar.category"),
        this.$t("admin.image"),
        this.$t("admin_sidebar.video"),
        this.$t("admin.keywords"),
        this.$t("admin.edit"),
        this.$t("admin.delete"),
      ],
      allBlog: [],
      allCategory: [],
      checkedItems: [],
      active_id: "",
      update: {
        keywords: [],
        show: false,
        titleEN: "",
        titleKA: "",
        subTitleKA: "",
        subTitleEN: "",
        authorEN: "",
        authorKA: "",
        descriptionEN: "",
        descriptionKA: "",
        category: null,
        image: null,
        video: null,
      },
      components: [{ comp: "comp" }],
      server_url: env.server_url,
      isLoading: false,
      modalShow: false,
      lang: "ka",
    };
  },
  mounted() {
    this.getBlog();
    this.getCategory();
    if (localStorage.getItem("lang") == "ka") {
      this.lang = "ka";
    } else {
      this.lang = "en";
    }
  },
  methods: {
    getCategory() {
      axios.get(`${env.host}/get/all/categories/admin`).then((res) => {
        res.data.item.map((item) => {
          this.allCategory.push({
            nameKA: item.titleKA,
            nameEN: item.titleEN,
            id: item._id,
          });
        });
      });
    },
    getBlog() {
      this.isLoading = true;
      axios
        .get(`${env.host}/get/all/blog/${null}/${null}/${null}`)
        .then((res) => {
          res.data.item.map((blog, index) => {
            this.$nextTick(() => {
              this.$refs["descriptionKA" + index].forEach((element) => {
                element.insertAdjacentHTML("beforeend", blog.descriptionKA);
              });
            });
            this.$nextTick(() => {
              this.$refs["descriptionEN" + index].forEach((element) => {
                element.insertAdjacentHTML("beforeend", blog.descriptionEN);
              });
            });
          });
          this.isLoading = false;
          this.allBlog = res.data.item;
        });
    },
    previewFiles(e) {
      const image = e.target.files[0];
      if (e.target.files[0].size > 1000000) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: this.$t("errors.too_long"),
        });
      }
      this.update.image = image;
    },
    add_component() {
      this.components.push({ comp: "comp" });
    },
    delete_comp() {
      this.components.pop();
    },
    delete_keywords(index) {
      if (index > -1) {
        this.update.keywords.splice(index, 1);
      }
    },
    get_value(e) {
      this.update.keywords.push(e.target.value);
    },
    editBlog(
      id,
      show,
      titleEN,
      titleKA,
      subTitleKA,
      subTitleEN,
      authorEN,
      authorKA,
      descriptionKA,
      descriptionEN,
      category,
      image,
      video,
      keywords
    ) {
      this.active_id = id;
      console.log(this.active_id);
      this.update.show = show;
      this.update.titleEN = titleEN;
      this.update.titleKA = titleKA;
      this.update.subTitleKA = subTitleKA;
      this.update.subTitleEN = subTitleEN;
      this.update.authorEN = authorEN;
      this.update.authorKA = authorKA;
      this.update.descriptionKA = descriptionKA;
      this.update.descriptionEN = descriptionEN;
      this.update.category = category;
      this.update.image = image;
      this.update.video = video;
      this.update.keywords = keywords;
    },
    UpdateBlog() {
      this.isLoading = true;
      if (this.update.video == null && this.update.image == null) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: this.$t("errors.enter_parameters"),
        });
        this.isLoading = false;
      }
      if (typeof this.update.image !== "object") {
        this.update.image = null;
      }
      const formData = new FormData();
      for (const i of Object.keys(this.update.keywords)) {
        formData.append("keywords", this.update.keywords[i]);
      }
      if (this.update.category === null) {
        formData.append("category", null);
      } else {
        formData.append("category", this.update.category._id);
      }
      console.log(this.update.category);
      console.log(this.active_id);
      formData.append("show", this.update.show);
      formData.append("descriptionKA", this.update.descriptionKA);
      formData.append("descriptionEN", this.update.descriptionEN);
      formData.append("titleKA", this.update.titleKA);
      formData.append("titleEN", this.update.titleEN);
      formData.append("subTitleKA", this.update.subTitleKA);
      formData.append("subTitleEN", this.update.subTitleEN);
      formData.append("authorEN", this.update.authorEN);
      formData.append("authorKA", this.update.authorKA);
      formData.append("image", this.update.image);
      if (this.update.video === "") {
        formData.append("video", "");
      } else {
        formData.append("video", this.update.video);
      }
      console.log(this.update);
      axios
        .post(`${env.host}/edit/blog/${this.active_id}`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(() => {
          Swal.fire({
            icon: "success",
            text: this.$t("success.success_edit"),
          });
          this.isLoading = false;
          this.modalShow = false;
          setTimeout(() => {
            this.getBlog();
          }, 1000);
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
    deleteBlog(id) {
      this.isLoading = true;
      axios.delete(`${env.host}/delete/blog/${id}`).then(() => {
        Swal.fire({
          icon: "success",
          text: this.$t("success.success_delete"),
        });
        this.isLoading = false;
        this.modalShow = false;
        this.getBlog();
      });
    },
  },
};
</script>

<style scoped>
.blog_management {
  width: 100%;
}
label {
  margin-top: 20px;
  font-family: mtavruliBOLD !important;
}
.show_on_front {
  font-size: 30px;
}
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #7e549374;
  width: 7px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5e168274;
  cursor: pointer;
}
.Container {
  width: 100% !important;
  min-height: calc(100vh - 150px) !important;
  max-height: calc(100vh - 150px) !important;
  overflow-y: scroll;
}
/* .Flipped,
.Flipped .Content {
  transform: rotateX(180deg);
  -ms-transform: rotateX(180deg);
  -webkit-transform: rotateX(180deg);
} */
.submit {
  width: 100%;
  height: 45px;
  border: none;
  background: #7e5493;
  border-radius: 0px;
  cursor: pointer;
  outline: none;
  color: white;
}
button {
  background: #7e5493;
  border: none;
  padding: 10px 30px 10px 30px;
  border-radius: 0px;
}
.delete {
  background: #f02849;
}
.short_text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
@media all and (max-width: 1450px) {
}
table {
  border-collapse: collapse;
  width: 100%;
  border-spacing: 0;
  border-radius: 0px;
  overflow: hidden;
}
thead {
  visibility: hidden;
  position: absolute;
  width: 0;
  height: 0;
}
th {
  background: #7e5493;
  color: #fff;
}
td:nth-child(1) {
  background: #7e5493;
  color: #fff;
  border-radius: 0px;
}
.add_comp i {
  cursor: pointer;
  margin-left: 20px;
  font-size: 20px;
}
.delete i {
  color: white;
}
th,
td {
  padding: 10px;
}
tr,
td {
  display: block;
}
td {
  position: relative;
}
td::before {
  content: attr(data-label);
  position: absolute;
  left: 0;
  padding-left: 1em;
  font-weight: 600;
  font-size: 0.9em;
  text-transform: uppercase;
}
tr {
  margin-bottom: 1.5em;
  border: 1px solid #ddd;
  border-radius: 0px;
  text-align: right;
}
tr:last-of-type {
  margin-bottom: 0;
}
td:nth-child(n + 2):nth-child(odd) {
  background-color: #ddd;
}
@media only screen and (min-width: 768px) {
  thead {
    visibility: visible;
    position: relative;
  }
  th {
    text-align: left;
    text-transform: uppercase;
    font-size: 0.9em;
  }
  tr {
    display: table-row;
    border: none;
    border-radius: 0;
    text-align: left;
  }

  tr:nth-child(even) {
    background-color: #ddd;
  }

  td {
    display: table-cell;
  }

  td::before {
    content: none;
  }

  td:nth-child(1) {
    background: transparent;
    color: #444;
    border-radius: 0;
  }

  td:nth-child(n + 2):nth-child(odd) {
    background-color: transparent;
  }
}
.checkbox {
  margin-bottom: 20px;
  margin-left: 10px;
}
input[type="checkbox"] {
  -ms-transform: scale(2); /* IE */
  -moz-transform: scale(2); /* FF */
  -webkit-transform: scale(2); /* Safari and Chrome */
  -o-transform: scale(2); /* Opera */
  transform: scale(2);
  padding: 10px;
}
.input,
select,
.choosed_keywords {
  border-radius: 0px;
  width: 100%;
  border: 1px solid #cccccc;
  margin-bottom: 20px;
  height: 40px;
  padding-left: 10px;
}
</style>
