<template>
  <div class="blog">
    <b-button @click="modalShow = !modalShow">
      {{ $t("add_blog.add_blog") }}</b-button
    >
    <b-modal id="modal-xl" v-model="modalShow" size="xl" hide-footer>
      <form @submit.prevent="addBlog()">
        <div class="text_editor">
          <label for="">{{ $t("admin.title") }} (KA)</label>
          <b-form-input v-model="form.titleKA"></b-form-input>
          <label for="">{{ $t("admin.title") }} (EN)</label>
          <b-form-input v-model="form.titleEN"></b-form-input>
        </div>
        <div class="text_editor">
          <label for="">{{ $t("add_blog.subtitle") }} (KA)</label>
          <vue-editor v-model="form.subTitleKA"></vue-editor>
          <label for="">{{ $t("add_blog.subtitle") }} (EN)</label>
          <vue-editor v-model="form.subTitleEN"></vue-editor>
        </div>
        <div class="text_editor">
          <label for="">{{ $t("add_blog.author") }} (KA)</label>
          <vue-editor v-model="form.authorKA"></vue-editor>
          <label for="">{{ $t("add_blog.author") }} (EN)</label>
          <vue-editor v-model="form.authorEN"></vue-editor>
        </div>
        <div class="text_editor">
          <label for="">{{ $t("admin.description") }} (KA)</label>
          <vue-editor v-model="form.descriptionKA"></vue-editor>
          <label for="">{{ $t("admin.description") }} (EN)</label>
          <vue-editor v-model="form.descriptionEN"></vue-editor>
        </div>
        <div class="text_editor">
          <label for=""> {{ $t("admin_sidebar.video") }}</label>
          <input class="input" type="text" ref="video" v-model="form.video" />
        </div>
        <div>
          <label for="">{{ $t("admin_sidebar.category") }}</label>
          <select name="" id="" v-model="form.category">
            <option value="" disabled selected>
              {{ $t("add_category.choose_category") }}
            </option>
            <option
              :value="category.id"
              v-for="category in allCategory"
              :key="category.id"
            >
              {{ category.name }}
            </option>
          </select>
        </div>
        <div class="text_editor">
          <label for=""> {{ $t("admin.keywords") }}</label>
          <i class="fal fa-plus-square" @click="add_component()"></i>
          <div v-for="(comp, i) in components" :key="comp.id" class="d-flex">
            <input
              class="input add_component"
              type="text"
              v-model="comp[i]"
              @change="get_value($event)"
            />
            <i class="fal fa-times-square delete_comp" @click="delete_comp"></i>
          </div>
        </div>
        <div>
          <label for="">{{ $t("admin.activate_on_front") }}</label>
        </div>
        <input class="checkbox" type="checkbox" v-model="form.show" />
        <input
          type="file"
          style="margin: 10px 0"
          accept="image/jpg, image/png, image/jpeg"
          class="input"
          ref="image"
          @change="previewFiles"
        />
        <button type="submit" class="submit btn btn-primary">
          <span v-show="!isLoading">{{ $t("admin.add") }}</span>
          <span v-show="isLoading">
            {{ $t("login.loading") }}
            <spinner v-show="isLoading" text="loading" />
          </span>
        </button>
      </form>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import env from "../../../env.json";
import { VueEditor } from "vue2-editor";
import spinner from "../../spinner/Spinner.vue";
import Swal from "sweetalert2";

export default {
  components: {
    VueEditor,
    spinner,
  },
  props: {
    getBlog: { type: Function },
  },
  name: "addblog",
  data() {
    return {
      form: {
        keywords: [],
        show: false,
        titleEN: "",
        titleKA: "",
        subTitleKA: "",
        subTitleEN: "",
        authorEN: "",
        authorKA: "",
        descriptionEN: "",
        descriptionKA: "",
        category: null,
        image: null,
        video: null,
      },
      allCategory: [],
      components: [{ comp: "comp" }],
      isLoading: false,
      modalShow: false,
    };
  },
  mounted() {
    this.getCategory();
  },
  methods: {
    nameKeydown(e) {
      if (/^\W$/.test(e.key)) {
        e.preventDefault();
      }
    },
    getCategory() {
      axios.get(`${env.host}/get/all/categories/admin`).then((res) => {
        res.data.item.map((item) => {
          this.allCategory.push({
            name: item.titleKA,
            id: item._id,
          });
        });
      });
    },
    previewFiles(e) {
      const image = e.target.files[0];
      if (e.target.files[0].size > 1000000) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: this.$t("errors.too_long"),
        });
      }
      this.form.image = image;
    },
    add_component() {
      this.components.push({ comp: "comp" });
    },
    delete_comp() {
      this.components.pop();
    },
    get_value(e) {
      this.form.keywords.push(e.target.value);
    },
    addBlog() {
      this.isLoading = true;
      if (this.form.video == null && this.form.image == null) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: this.$t("errors.enter_parameters"),
        });
        this.isLoading = false;
      }
      const formData = new FormData();
      for (const i of Object.keys(this.form.keywords)) {
        formData.append("keywords", this.form.keywords[i]);
      }
      formData.append("show", this.form.show);
      formData.append("image", this.form.image);
      formData.append("titleKA", this.form.titleKA);
      formData.append("titleEN", this.form.titleEN);
      formData.append("subTitleKA", this.form.subTitleKA);
      formData.append("subTitleEN", this.form.subTitleEN);
      formData.append("authorEN", this.form.authorEN);
      formData.append("authorKA", this.form.authorKA);
      formData.append("descriptionEN", this.form.descriptionEN);
      formData.append("descriptionKA", this.form.descriptionKA);
      formData.append("category", this.form.category);
      formData.append("video", this.form.video);
      axios
        .post(`${env.host}/add/blog`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(() => {
          Swal.fire({
            icon: "success",
            text: this.$t("success.success_add"),
          });
          this.isLoading = false;
          this.modalShow = false;
          this.emptyValues();
          setTimeout(() => {
            this.getBlog();
          }, 1000);
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
    emptyValues() {
      this.components = [{ comp: "comp" }];
      this.form.show = false;
      this.form.titleEN = "";
      this.form.titleKA = "";
      this.form.subTitleKA = "";
      this.form.subTitleEN = "";
      this.form.authorEN = "";
      this.form.authorKA = "";
      this.form.descriptionEN = "";
      this.form.descriptionKA = "";
      this.form.category = null;
      this.form.image = null;
      this.form.video = null;
    },
  },
};
</script>

<style scoped>
label {
  font-family: mtavruliBOLD;
  margin-top: 15px;
}
label {
  font-family: mtavruliBOLD !important;
}
button {
  background: #7e5493;
  border: none;
  padding: 10px 30px 10px 30px;
  border-radius: 0px;
}
.blog {
  margin-bottom: 10px;
}
label {
  margin-top: 20px;
}
.checkbox {
  margin-bottom: 20px;
  margin-left: 10px;
}
input[type="checkbox"] {
  -ms-transform: scale(2); /* IE */
  -moz-transform: scale(2); /* FF */
  -webkit-transform: scale(2); /* Safari and Chrome */
  -o-transform: scale(2); /* Opera */
  transform: scale(2);
  padding: 10px;
}
.input,
select {
  border-radius: 0px;
  width: 100%;
  border: 1px solid #cccccc;
  height: 40px;
  padding-left: 10px;
}
.submit {
  width: 100%;
  height: 45px;
  border: none;
  background: #7e5493;
  border-radius: 0px;
  cursor: pointer;
  outline: none;
  color: white;
}
i {
  cursor: pointer;
  margin-left: 20px;
  font-size: 20px;
}
.add_component {
  margin-bottom: 20px;
}
</style>
